@media (min-width: 600px) {
  .MuiContainer-root {
    max-width: 100% !important;
  }
}
@media (min-width: 900px) {
  .MuiContainer-root {
    max-width: 100% !important;
  }
}
@media (min-width: 900px) {
  .MuiContainer-root {
    max-width: 100% !important;
  }
}
@media (min-width: 1366px) {
  .MuiContainer-root {
    max-width: 1300px !important;
  }
}
@media (min-width: 1536px) {
  .MuiContainer-root {
    max-width: 1500px !important;
  }
}
@media (min-width: 768px) {
  .search-field-mobile {
    display: none;
  }
}
@media (max-width: 1450px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
  h3 {
    font-size: 24px;
    line-height: 32px;
  }
  h4 {
    font-size: 20px;
    line-height: 26px;
  }
  .main-content {
    padding: 16px;
  }
  .auth-page h2 {
    margin: 32px 0 16px;
  }
  .login-form .btn-primary {
    margin-top: 24px;
  }
  .auth-page .col-right .login-form {
    max-width: 400px;
    padding: 16px;
  }
  .auth-page .col-right img {
    max-width: 240px;
  }
  .header-content {
    padding: 12px 40px;
  }
  .task-view-list li {
    font-size: 20px;
    line-height: 26px;
  }
  .page-not-found .page-not-img {
    max-width: 400px;
    margin: 16px 0;
  }
  .page-not-found .logo-img {
    max-width: 150px;
    margin-bottom: 16px;
  }
  .page-not-found p {
    margin: 12px 0;
    max-width: 350px;
  }
  .page-not-found .btn-primary {
    min-width: 170px;
    margin-top: 12px;
  }
  .CircularProgressbar-text {
    font-size: 16px !important;
  }
  .header-content .header-search-field .MuiAutocomplete-root {
    width: 300px;
  }
  .header-content .header-search-field {
    width: 65%;
  }
  .about-container .about-employer-content {
    min-height: calc(100vh - 180px);
  }
}
@media (max-width: 1366px) {
  .learning-card {
    gap: 20px;
  }
  .learning-card .card {
    width: calc(100% / 3 - 16px);
  }
}
@media (max-width: 991px) {
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  h3 {
    font-size: 20px;
    line-height: 26px;
  }
  h5 {
    font-size: 16px;
    line-height: 22px;
  }
  p,
  .dropdown-btn,
  .btn,
  .dropdown-items ul li,
  .MuiFormControlLabel-label,
  .module-accordion .MuiAccordionDetails-root .module-task li,
  .MuiInputLabel-root,
  a,
  .CTextField input,
  .employer-details .details-list span,.support-content .edit-profile-form form .support-file label {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .btn {
    height: 42px;
  }
  .login-form .CTextField {
    margin-top: 12px;
  }
  .login-form .btn-primary {
    margin-top: 16px;
  }
  .home-progress-content .circleProgress {
    width: 100px;
    height: 100px;
  }
  .home-progress-content .progress-field:first-child:after {
    top: -10px;
    height: calc(100% + 20px);
    right: 20px;
  }
  .home-progress-content .progress-field:first-child {
    padding-right: 30px;
  }
  .header-content {
    padding: 12px;
  }
  .module-view .card-box,
  .card-box,
  .task-view .card-box,
  .task-details-content,
  .setting-container .setting-content .card-box {
    padding: 16px;
  }
  .accordion-start-btn {
    min-width: 100px !important;
  }
  .MuiAccordionSummary-content {
    padding-left: 24px;
  }
  .MuiAccordionSummary-content .acc-title svg {
    margin-left: 8px;
  }
  .MuiAccordionDetails-root,
  .module-accordion h5.module-title {
    padding-left: 40px !important;
  }
  .module-view .card-box,
  .task-view .card-box {
    margin-top: 16px;
  }
  .setting-container .setting-content {
    gap: 10px;
  }
  .setting-container .setting-content .col-left {
    width: calc(35% - 8px);
  }
  .setting-container .setting-content .col-right {
    width: calc(65% - 8px);
  }
  .profile-menu .profile-logout-btn {
    bottom: 16px;
  }
  .page-not-found {
    padding: 16px;
  }
  .page-not-found .page-not-img {
    max-width: 100%;
    margin: 12px 0;
  }
  .MuiBreadcrumbs-li div {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .accordion-start-btn {
    height: 36px;
    min-width: 100px !important;
  }
  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper svg {
    font-size: 20px;
  }
  .MuiAccordionSummary-content .acc-title svg {
    font-size: 20px;
  }
  .header-content .header-search-field .MuiAutocomplete-root {
    width: 250px;
  }
  ::placeholder,
  .MuiAutocomplete-option,
  .MuiAutocomplete-input,
  .MuiAutocomplete-noOptions {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .about-container .about-employer-content {
    min-height: calc(100vh - 164px);
    margin-top: 16px !important;
  }
  .about-container .about-employer-content {
    padding: 16px;
  }
  .header-content .header-middle {
    width: calc(100% - 350px);
  }
  .header-content img {
    width: 130px;
    height: 45px;
  }
  .home-progress-content {
    padding: 16px;
    margin-top: 16px;
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 24px;
    line-height: 30px;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
  }
  h5 {
    font-size: 16px;
    line-height: 22px;
  }

  .auth-page {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .auth-page .col-left {
    width: 100%;
  }
  .auth-page .col-right {
    background-color: var(--whiteColor);
    border-radius: 15px;
    position: absolute;
    padding: 32px 24px;
    width: calc(100% - 32px);
    max-width: 400px;
  }
  .auth-page h2 {
    margin: 16px 0;
  }
  .auth-page .col-right .login-form {
    padding: 0;
  }
  .auth-page .col-right img {
    max-width: 180px;
    height: 100px;
  }
  .learning-card {
    gap: 16px;
    margin-top: 16px;
  }
  .learning-card .card {
    width: calc(100% / 2 - 8px);
  }
  .header-content .header-middle {
    justify-content: end;
    width: calc(100% - 310px);
  }
  .home-progress-content .progress-field {
    width: 100%;
  }
  .home-progress-content .circle-progress {
    width: calc(50% - 8px);
  }
  .home-progress-content .progress-field:first-child {
    padding: 0;
    margin-bottom: 16px;
  }
  .home-progress-content .progress-field:first-child:after {
    display: none;
  }
  .header-content img {
    width: 130px;
  }
  .contract-body .contract-fields .top-fields {
    width: calc(50% - 4px);
    gap: 4px;
  }
  .contract-body .contract-fields .end-text {
    width: calc(50% - 4px);
  }
  .contract-body p,
  .contract-body span,
  .contract-body li {
    font-size: 13px;
    line-height: 19px;
  }
  .contract-body .contract-fields p,
  .contract-body .contract-fields span {
    white-space: normal;
  }
  .card-box {
    padding: 16px;
  }
  .main-content {
    min-height: calc(100vh - 137px);
  }
  .options-open + div.main-content {
    height: calc(100vh - 137px);
    min-height: unset;
    overflow-y: hidden;
  }
  .contract-page {
    height: calc(100vh - 102px);
  }
  .contract-body {
    height: calc(100% - 130px);
  }
  .task-view-list li {
    font-size: 18px;
    line-height: 24px;
    padding: 16px;
    padding-left: 40px;
  }
  .task-view-list li:before {
    left: 20px;
    width: 4px;
  }

  .MuiBreadcrumbs-separator .MuiSvgIcon-root {
    font-size: 18px;
  }
  .breadcrumb-back {
    gap: 4px;
  }
  .task-details-content hr {
    margin: 16px 0;
  }
  .task-details-content .details-img img {
    height: 230px;
  }
  .profile-btn button {
    min-width: auto !important;
  }
  .setting-container .setting-content {
    gap: 16px;
    justify-content: flex-start;
    height: auto;
  }
  .setting-container .setting-content .col-left,
  .setting-container .setting-content .col-right {
    width: 100%;
    height: fit-content !important;
  }
  .setting-container .setting-content .col-left .profile-logout-btn {
    bottom: unset;
    top: -26px;
    right: 0;
    width: fit-content;
    left: unset;
    padding: 0 !important;
  }
  .setting-container .setting-content .col-left .profile-logout-btn a {
    height: 42px !important;
    padding: 4px 8px !important;
  }
  .profile-menu .MuiListItem-root.profile-logout-btn {
    position: static !important;
    width: 100% !important;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
  }
  .profile-menu .MuiListItem-root.profile-logout-btn a {
    margin: 0 !important;
    width: 100%;
  }
  .profile-menu .MuiListItem-root.profile-logout-btn a svg {
    width: 20px;
    margin-right: 4px;
  }
  .profile-menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: fit-content !important;
    padding-top: 8px !important;
  }
  .profile-menu .MuiListItem-root {
    width: 100%;
    padding-bottom: 0 !important;
  }
  .profile-content {
    align-items: flex-start;
  }
  .profile-content .profile-upload {
    gap: 8px;
  }
  .edit-profile-form .CTextField {
    margin-top: 12px;
  }
  .header-content .header-profile span {
    font-size: 16px;
  }
  .user-name h2 {
    font-size: 20px;
    line-height: 26px;
  }
  .user-name .user-letter {
    width: 50px;
    height: 50px;
    min-width: 50px;
    font-size: 24px;
    margin-right: 12px;
  }
  .MuiAccordionSummary-content .acc-title h5 {
    font-size: 14px;
    line-height: 20px;
  }
  .module-accordion .MuiAccordionSummary-root {
    padding: 0 12px !important;
  }
  .module-accordion .MuiAccordionSummary-content {
    flex-wrap: wrap;
  }
  .module-accordion .MuiAccordionSummary-content .acc-title {
    width: 100%;
    padding-right: 0;
  }
  .accordion-start-btn {
    height: 30px !important;
    font-size: 12px !important;
    line-height: 18px !important;
    min-width: 70px !important;
    margin-top: 4px !important;
  }
  .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper {
    top: 10px;
  }
  .MuiAccordionSummary-content .acc-title svg {
    align-self: self-start;
    margin-top: 2px;
  }
  .module-accordion .MuiAccordionDetails-root {
    padding-left: 24px !important;
  }

  .setting-container .setting-content {
    margin: 16px auto 0 auto;
  }
  .task-details-main span,
  .task-details-main strong,
  .task-details-main p,
  .task-details-main li {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .task-details-main ol,
  .task-details-main ul {
    padding-left: 24px !important;
  }
  .module-accordion .MuiAccordionDetails-root .module-task li {
    font-size: 13px !important;
    line-height: 19px !important;
    margin: 8px 0;
  }
  .header-content .header-search-field.desktop-search-field {
    display: none;
  }
  .home-content {
    padding-top: 0 !important;
  }
  .about-container .about-employer-content {
    min-height: calc(100vh - 220px);
  }
  .employer-details {
    padding: 16px 0;
  }
  .employer-details .details-list span {
    min-width: 130px;
  }
  .employer-details h3 {
    margin-bottom: 16px;
  }
  .about-container .about-employer-content .employer-logo {
    margin: 16px 0;
  }
  .profile-upload .uploaded-text {
    font-size: 24px;
    width: 65px;
    height: 65px;
  }
  .upload__image-wrapper .image-item__btn-wrapper {
    margin-top: 4px;
  }

  .auth-page .col-left {
    padding: 0;
  }
  .auth-page .col-left img {
    border-radius: 0;
  }

  .MuiAutocomplete-option {
    min-height: auto !important;
  }
  .wallet-settings {
    min-height: auto !important;
  }
  .wallet-content span {
    font-size: 24px;
    line-height: 30px;
  }
  .header-content .user-name {
    width: 0;
  }
  .employer-details .employer-img-list {
    display: block;
  }
  .employer-details .employer-img-list img {
    margin-left: 0 !important;
  }
  .employer-details .employer-img-list span {
    display: block;
    margin-bottom: 8px;
  }
}

@media (max-width: 575px) {
  h3 {
    font-size: 20px;
    line-height: 26px;
  }
  .learning-card .card,
  .home-progress-content .circle-progress {
    width: 100%;
  }
  .home-progress-content .progress-field {
    margin: 16px 0;
  }
  .header-content .header-middle {
    justify-content: end;
    width: calc(100% - 200px);
  }
  .header-content .header-profile {
    width: 40px !important;
    height: 40px !important;
  }
  .main-content {
    min-height: calc(100vh - 134px);
  }
  .options-open + div.main-content {
    height: calc(100vh - 134px);
    min-height: unset;
    overflow-y: hidden;
  }
  .contract-page {
    height: calc(100vh - 102px);
  }
  .contract-body {
    height: calc(100% - 138px);
  }
  .contract-body .contract-fields .top-fields,
  .contract-body .contract-fields .end-text {
    width: 100%;
  }
  .contract-body ul li {
    margin: 8px 0;
  }
  .profile-btn .header-profile {
    margin-right: 0;
  }
  .profile-btn span {
    display: none;
  }
  .header-content .header-profile span {
    display: flex;
  }
  .profile-menu .MuiListItem-root {
    width: 100%;
  }
  .profile-menu .MuiListItem-root a {
    padding: 8px 12px;
  }
  .profile-menu {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .profile-menu .MuiListItem-root {
    padding: 8px 0 0 0 !important;
  }
  .page-not-found .page-not-img {
    max-width: 300px;
  }
  .contract-pdf-employer-header-title{
  font-size: 25px !important ;
  }
  .contract-pdf-right-title{
    font-size: 11px !important;
  }
}
@media (max-width: 400px) {
  .contract-body {
    height: calc(100% - 160px);
  }
  .page-not-found .page-not-img {
    max-width: 300px;
  }
  .contract-pdf-employer-header-title{
    font-size: 20px !important ;
    
    }
    .contract-pdf-right-title{
      font-size: 10px !important;
    }
}
