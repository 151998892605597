@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Bold.woff2") format("woff2"),
    url("../fonts/Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Medium.woff2") format("woff2"),
    url("../fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Light.woff2") format("woff2"),
    url("../fonts/Inter-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-SemiBold.woff2") format("woff2"),
    url("../fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
:root {
  --primaryColor: #10a790;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --darkColor: #323232;
  --greyColor: #8a92a6;
  --greyColor2: #e2e8f0;
  --dangerColor: #fd2b2b;
  --thin-greyColor: #f8fafc;
  --shadow-primaryColor: #10a79033;
  --shadow-blueColor: #3b82f61f;
  --hover-primaryColor: #0d8e7e;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
  font-family: "Inter", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
li,
strong,
b,
label,
input,
button,
div {
  font-family: "Inter", sans-serif !important;
}
h2 {
  font-size: 32px;
  line-height: 40px;
}
h3 {
  font-size: 28px;
  line-height: 36px;
}
h4 {
  font-size: 24px;
  line-height: 32px;
}
h5 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}
p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.btn {
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  height: 50px;
  border: 1px solid !important;
  border-radius: 5px !important;
  padding: 8px 16px !important;
}
.btn-primary {
  color: var(--whiteColor) !important;
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
  transition: all 0.3s;
}
.btn-primary:hover {
  background-color: var(--hover-primaryColor) !important;
  border-color: var(--hover-primaryColor) !important;
  transition: all 0.3s;
}
.btn-outlined {
  color: var(--blackColor) !important;
  background-color: transparent !important;
  border-color: var(--blackColor) !important;
  transition: all 0.3s;
}
.btn-outlined:hover {
  background-color: var(--primaryColor) !important;
  color: var(--whiteColor) !important;
  border-color: var(--primaryColor) !important;
  transition: all 0.3s;
}
.btn-text {
  border: none !important;
  color: var(--blackColor) !important;
}
.btn-text svg {
  color: var(--darkColor) !important;
  font-size: 16px;
  margin-left: 4px;
  margin-right: 11px;
}
.btn-text:hover {
  background-color: transparent !important;
}
.btn-primary .btn-icon-right {
  color: var(--whiteColor);
  font-size: 16px;
  margin-left: 4px;
}
.btn-primary .btn-icon-left {
  margin-right: 8px;
}
.btn-primary svg {
  color: var(--whiteColor);
}
.w-auto {
  width: auto;
}
.w-full {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.MuiSvgIcon-root {
  color: var(--blackColor);
}
.MuiFormHelperText-root {
  margin: 4px 0 0 0 !important;
  color: var(--dangerColor) !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.MuiInputLabel-root {
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 400;
  color: var(--blackColor) !important;
  position: static !important;
  transform: unset !important;
  margin-bottom: 4px;
}
.Mui-disabled {
  opacity: 0.5;
}
.CTextarea {
  margin: 16px 0;
}
.CTextarea .MuiInputBase-root {
  padding: 13.5px 16px !important;
}
.CTextarea .additional-label {
  font-weight: 600;
}
.CTextarea .MuiFormLabel-root {
  white-space: normal !important;
}
/* .CTextarea .MuiInputBase-root textarea {
  overflow-y: auto;
  max-height: 120px;
  min-height: 0 !important;
} */
/****TextField****/
.CTextField {
  margin-top: 10px;
}
.CTextField input::placeholder {
  color: var(--greyColor);
  opacity: 1;
}
.CTextField legend {
  display: none !important;
}
.CTextField fieldset {
  top: 0 !important;
  border-color: var(--greyColor) !important;
}
.CTextField input {
  padding: 13.5px !important;
  font-size: 16px;
  line-height: 22px;
  color: var(--blackColor);
}
.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--primaryColor) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/****Progress****/
.CircularProgressbar .CircularProgressbar-trail {
  stroke: var(--greyColor2) !important;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: var(--primaryColor) !important;
}
.CircularProgressbar-text {
  font-weight: 600 !important;
  fill: var(--primaryColor) !important;
  font-size: 18px !important;
}
/****Dropdown****/
.MuiPopover-paper.MuiMenu-paper {
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor);
  border-radius: 15px;
  border: 1px solid var(--greyColor2);
}
.dropdown-items ul li {
  color: var(--blackColor);
  font-size: 16px;
  line-height: 22px;
  min-height: auto;
  padding: 8px 16px;
}
.dropdown-items ul li svg {
  margin-right: 8px;
  color: var(--blackColor);
  font-size: 22px;
}
.dropdown-items ul li:hover {
  background-color: var(--primaryColor);
  color: var(--whiteColor);
}
.dropdown-items ul li:hover svg {
  color: var(--whiteColor);
}
.dropdown-btn {
  color: var(--blackColor) !important;
  text-transform: unset !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
}
.dropdown-btn:hover {
  background-color: transparent !important;
}
/*****/
.MuiAutocomplete-popupIndicator {
  display: none !important;
}
.CAutoComplete .MuiInputBase-root {
  padding: 0 16px !important;
}
.CAutoComplete .MuiInputBase-root input {
  padding: 13.5px 8px !important;
}

.MuiAutocomplete-paper {
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor) !important;
  border: 1px solid var(--greyColor2) !important;
  border-radius: 5px !important;
}
.MuiAutocomplete-option.Mui-focused {
  background-color: var(--primaryColor) !important;
  color: var(--whiteColor) !important;
}
.MuiAutocomplete-noOptions {
  color: var(--blackColor) !important;
  min-height: auto;
}
.search-field-mobile {
  width: calc(100% - 32px);
  margin-left: 16px;
  margin-top: 16px;
}

/****Loader****/
.CLoader {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  /* display: none; */
}
.CLoader svg {
  color: var(--primaryColor);
}
.MuiCircularProgress-root {
  position: relative;
  z-index: 100;
}
.CLoader:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--blackColor);
  opacity: 0.5;
  z-index: 1;
}
/***BreadCrumb****/
.MuiBreadcrumbs-li div,
.MuiBreadcrumbs-li a {
  text-decoration: none !important;
  font-size: 16px;
  line-height: 22px;
  color: var(--blackColor);
}
.MuiBreadcrumbs-li div {
  color: var(--greyColor);
}
.MuiBreadcrumbs-li:last-child div {
  color: var(--greyColor);
}
.MuiBreadcrumbs-separator {
  margin: 0 4px !important;
}

/****Login Page Css****/
.auth-page {
  display: flex;
  align-items: center;
}
.auth-page h2 {
  margin: 64px 0 32px 0;
}
.auth-page .col-left {
  height: 100vh;
  width: 55%;
  overflow-y: hidden;
  padding: 16px;
}
.auth-page .col-left img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.auth-page .col-right {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.auth-page .col-right img {
  width: 100%;
  max-width: 200px;
  height: 120px;
  object-fit: contain;
}
.auth-page .col-right .login-form {
  max-width: 440px;
}
.login-form .CTextField {
  margin-top: 20px;
}
.login-form .CCheckbox svg,
.login-form .CCheckbox {
  color: var(--greyColor);
}
.login-form .CCheckbox .MuiCheckbox-root.Mui-checked svg {
  color: var(--blackColor);
}
.login-form .btn-primary {
  margin-top: 32px;
}

/*****Header***/
.header-content {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--whiteColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 60px;
  box-shadow: 0px 4px 17.6px 0px #0000001a;
}
.header-content .header-logo {
  display: flex;
  align-items: center;
}
.header-content img {
  width: 170px;
  height: 60px;
  object-fit: contain;
}
.header-content .header-search-field {
  width: 50%;
  display: flex;
  justify-content: end;
}
.header-content .header-search-field .MuiAutocomplete-root {
  width: 450px;
}
.header-content .header-middle {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 400px);
  align-items: center;
}
.header-content .header-profile {
  width: 50px !important;
  min-width: unset !important;
  height: 50px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 8px;
  border: 1px solid var(--greyColor2);
}
.header-content .header-profile svg {
  color: var(--whiteColor);
}
.profile-btn button {
  padding: 0 !important;
}
.header-content .header-profile span {
  font-size: 24px;
  font-weight: 700;
  color: var(--whiteColor);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-content .header-profile span.profile-name-first-letter {
  background-color: var(--greyColor) !important;
}
.header-content .user-name {
  width: 100px;
  /* white-space: nowrap; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.header-content .header-profile span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.options-open + div.main-content {
  height: calc(100vh - 85px);
  min-height: unset;
  overflow-y: hidden;
}
/**Contract page***/

.main-content {
  background-color: var(--thin-greyColor);
  min-height: calc(100vh - 85px);
  padding: 24px;
}
.card-box {
  border-radius: 15px;
  padding: 24px;
  background-color: var(--whiteColor);
}
.contract-page {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  height: calc(100vh - 136px);
}
.contract-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0;
}
.contract-header img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  object-position: center;
}
.contract-header h5 {
  color: var(--blackColor);
  text-transform: uppercase;
  font-weight: bold;
}
.contract-body .contract-fields {
  display: flex;
  align-items: end;
  gap: 6px;
  width: 100%;
  margin-bottom: 12px;
  flex-wrap: wrap;
}
.contract-body .contract-fields .field-text {
  min-height: 42px;
  border: 1px solid var(--greyColor);
  width: 100%;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  white-space: normal;
  padding: 4px;
}
.contract-body .contract-fields p {
  font-weight: 600;
  color: var(--blackColor);
  white-space: nowrap;
}
.contract-body .contract-fields span {
  white-space: nowrap;
}
.contract-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}
.contract-body .contract-fields .top-fields {
  width: calc(70% - 4px);
  display: flex;
  align-items: end;
  gap: 6px;
}
.contract-body .contract-fields .end-text {
  width: calc(30% - 4px);
}
.contract-body {
  margin: 8px 0;
  padding: 8px 0;
  height: calc(100% - 190px);
  overflow-y: auto;
}

.contract-body .contract-fields .bottom-fields {
  width: calc(50% - 8px);
  margin-top: 16px;
}
.contract-body .contract-fields .signature-fields .field-text {
  min-height: 85px;
}
.contract-body .contract-fields .date-fields {
  display: flex;
  justify-content: end;
}
.contract-body .contract-fields .date-fields div {
  width: 100%;
  max-width: 180px;
}

/****Home***/
.home-content {
  padding: 24px 0 !important;
}
.user-name {
  display: flex;
  align-items: center;
}
.user-name .user-letter {
  border: 1px solid var(--blackColor);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 32px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.card {
  padding: 16px;
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor);
  border: 1px solid var(--greyColor2);
  border-radius: 15px;
  transition: all 0.3s;
  cursor: pointer;
}
.card img {
  width: 100%;
  padding: 25px;
  max-height: 220px;
  object-fit: contain;
  border-radius: 10px;
  object-position: center;
}
.card h5 {
  color: var(--blackColor);
  padding: 16px 0;
}
.card .btn {
  font-size: 14px !important;
  line-height: 20px !important;
  height: 42px;
  min-width: 120px;
  margin-top: 8px;
}
.learning-card .card:hover {
  border-color: var(--primaryColor);
  box-shadow: 0px 4px 34px 0px var(--shadow-primaryColor);
  transition: all 0.3s;
}
.learning-card .card:hover .btn-outlined {
  background-color: var(--primaryColor) !important;
  color: var(--whiteColor) !important;
  border-color: var(--primaryColor) !important;
}
.learning-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 24px;
  margin-top: 24px;
}
.learning-card .card {
  width: calc(100% / 4 - 18px);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}
.home-progress-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 24px;
  padding: 24px;
  gap: 12px;
}
.home-progress-content .progress-field {
  width: calc(100% / 3 - 8px);
  position: relative;
}
.home-progress-content .progress-field:not(:last-child)::after {
  content: "";
  background-color: var(--greyColor);
  position: absolute;
  right: 50px;
  top: -25px;
  height: calc(100% + 50px);
  top: calc(50% - 50px);
  height: 100px;
  width: 1px;
}
.home-progress-content .progress-field:not(:last-child) {
  padding-right: 60px;
}
.home-progress-content .circle-progress {
  display: flex;
  align-items: center;
  gap: 12px;
}
.home-progress-content .progress-field span {
  font-size: 14px;
  line-height: 20px;
  color: var(--blackColor);
}
.home-progress-content .circleProgress {
  width: 120px;
  height: 120px;
}
/*****Module View*****/
.breadcrumb-back {
  display: flex;
  align-items: center;
  gap: 12px;
}
.breadcrumb-back .btn {
  padding: 0 !important;
  height: auto !important;
  min-width: auto !important;
  margin-right: 8px;
}
.breadcrumb-back .btn svg {
  font-size: 32px;
}
.breadcrumb-back .btn:hover {
  background-color: transparent !important;
}
.module-view {
  padding: 0 !important;
}
.module-view .card-box {
  padding: 24px;
  margin-top: 24px;
  border: 1px solid var(--greyColor2);
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor);
}
.module-view .card-box p {
  color: var(--greyColor);
  margin-top: 10px;
}
.module-view .card-box .module-btn {
  text-align: end;
  margin-top: 24px;
}
.module-accordion {
  border: 1px solid var(--greyColor2);
  border-radius: 15px;
  overflow: hidden;
}
.module-accordion h5.module-title {
  padding: 12px 16px;
  padding-left: 48px;
  background: var(--greyColor2);
  font-weight: bold;
}
.module-accordion .MuiAccordionDetails-root .module-task li {
  font-size: 16px;
  line-height: 22px;
  margin: 12px 0;
  color: var(--blackColor);
  position: relative;
  padding-left: 20px;
}
.module-accordion .MuiAccordionDetails-root .module-task li:before {
  content: "";
  position: absolute;
  left: 1px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--blackColor);
  top: calc(50% - 3px);
}

/****Accordion css***/
.MuiAccordionSummary-root {
  border-top: 1px solid var(--greyColor2) !important;
}
.MuiAccordionSummary-content {
  align-items: center;
  justify-content: space-between;
  padding-left: 32px;
  position: relative;
}
.accordion-start-btn {
  height: 36px;
  font-size: 14px !important;
  line-height: 20px !important;
  min-width: 120px !important;
}
.accordion-start-btn svg {
  margin-right: 6px;
  width: 16px;
}
.MuiAccordionSummary-content .acc-title {
  display: flex;
  align-items: center;
  padding-right: 8px;
}
.MuiAccordionSummary-content .acc-title svg {
  margin-left: 12px;
  color: var(--primaryColor);
}
.MuiAccordionSummary-content,
.MuiAccordionSummary-content.Mui-expanded {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin: 0 !important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
  border-bottom: 1px solid var(--greyColor2);
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.MuiAccordionDetails-root {
  padding-left: 48px !important;
}
.MuiAccordionDetails-root.Mui-expanded {
  padding: 12px 16px !important;
  padding-left: 48px !important;
}

.MuiAccordionDetails-root ul li {
  list-style-type: none !important;
}
.MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  left: 10px;
}
.MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper svg {
  color: var(--blackColor);
}
.MuiAccordion-rounded.Mui-expanded {
  margin-bottom: 0 !important;
}
.MuiAccordionSummary-root h5 {
  color: var(--blackColor);
}
.MuiAccordion-root.Mui-expanded {
  margin-top: 0 !important;
}
.MuiAccordion-root {
  box-shadow: unset !important;
}
.MuiAccordion-root::before {
  display: none !important;
}
/***Task View****/
.task-view {
  padding: 0 !important;
}
.task-view .card-box {
  max-width: 930px;
  margin: 24px auto 0 auto;
  padding: 24px;
  border: 1px solid var(--greyColor2);
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor);
}
.task-view .card-box .task-btn {
  margin-top: 24px;
}
.task-view .card-box .task-btn .btn {
  min-width: 150px !important;
}
.task-view .card-box h3 {
  color: var(--blackColor);
}
.task-view .card-box p {
  margin-top: 10px;
}
.task-view .card-box p strong span {
  color: var(--blackColor) !important;
}
.task-view-list {
  padding: 24px 0 12px 0;
}
.task-view-list li {
  list-style-type: none !important;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor);
  padding: 18px;
  border: 1px solid var(--greyColor2);
  border-radius: 15px;
  margin: 16px 0;
  padding-left: 48px;
  position: relative;
}
.task-view-list li::before {
  content: "";
  position: absolute;
  left: 24px;
  top: 12px;
  width: 5px;
  border-radius: 3px;
  height: calc(100% - 24px);
  background-color: var(--primaryColor);
}
/**Task Details**/
.details-task {
  padding: 0 !important;
  overflow: hidden;
}
.task-details-heading {
  background-color: var(--greyColor2);
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.task-details-heading .btn-text {
  padding: 0 !important;
  height: auto !important;
}
.task-details-heading p {
  margin-top: 0 !important;
  color: var(--blackColor) !important;
}
.task-details-content {
  padding: 24px;
  position: relative;
}
.task-details-content hr {
  border-color: var(--greyColor2);
  opacity: 0.3 !important;
  margin: 20px 0;
}
.task-details-content h4 {
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--blackColor);
}
.task-details-content button {
  min-width: 150px;
}
.task-details-content .details-img {
  overflow: hidden;
  border-radius: 15px;
  margin-top: 16px;
}
.task-details-content .details-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.task-details-main span,
.task-details-main strong,
.task-details-main p,
.task-details-main li {
  font-size: 16px !important;
  line-height: 22px !important;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  margin-top: 16px;
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.assessment-qa,
.task-details-main,
.details-img,
.video-details-main {
  margin-top: 64px;
}
.video-details-main,
.details-img {
  margin-top: 48px;
}
.assessment-qa .task-details-continue {
  margin-top: 24px;
}
.assessment-task-heading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.task-details-content button.btn-text {
  min-width: auto !important;
}
.btn-text-prev {
  margin-right: 24px !important;
}
.task-details-main ol,
.task-details-main p,
.task-details-main div,
.task-details-main li,
.task-details-main strong {
  margin-left: 0 !important;
  font-family: "Inter", sans-serif !important;
  text-indent: unset !important;
  font-size: 15px !important;
  line-height: 22px !important;
}
.task-details-main ol,
.task-details-main ul {
  padding-left: 32px !important;
}
/****Profile*****/
.setting-container {
  padding: 0 !important;
}
.setting-container .setting-content {
  margin: 24px auto 0 auto;
  max-width: 1250px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  min-height: calc(100vh - 188px);
  position: relative;
}
.setting-container .support-content {
  justify-content: center;
}
.support-content .edit-profile-form form {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
}
.support-content .edit-profile-form form .MuiFormControl-root {
  width: calc(50% - 8px);
}
.support-content .edit-profile-form form .support-file label {
  margin-bottom: 4px;
  line-height: 22px;
  display: inline-block;
}
.upload-input {
  position: relative;
}
.upload-input button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: var(--primaryColor);
  width: 30px;
  height: 30px;
}
.upload-input button svg {
  font-size: 16px;
  color: var(--whiteColor);
}
.support-content .edit-profile-form {
  max-width: 100%;
}
.support-content .edit-profile-form .support-file {
  width: 100%;
}
.support-content .edit-profile-form .submit-btn {
  display: flex;
  justify-content: end;
  margin-top: 16px;
  width: 100%;
}
.support-content .edit-profile-form .submit-btn button {
  min-width: 150px;
}
.setting-container .setting-content .col-left {
  width: calc(25% - 12px);
}
.setting-container .setting-content .col-right {
  width: calc(75% - 12px);
}
.setting-container .setting-content .card-box {
  padding: 24px;
  border: 1px solid var(--greyColor2);
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor);
}
.profile-menu {
  height: 100%;
  position: relative;
}
.profile-menu .MuiListItem-root {
  padding: 12px 0 !important;
}
.profile-menu .MuiListItem-root a {
  text-decoration: none !important;
  display: flex;
  align-items: center !important;
  padding: 12px 16px;
  width: 100%;
  color: var(--blackColor) !important;
  border-radius: 5px;
  cursor: pointer;
}
.profile-menu .MuiListItem-root a svg {
  margin-right: 8px;
}
.profile-menu .MuiListItem-root a.active {
  background-color: var(--greyColor2) !important;
}
.profile-menu .profile-logout-btn {
  position: absolute;
  left: 0;
  bottom: 24px;
  display: flex;
  flex-direction: column;
}

.profile-menu .profile-logout-btn a,
.profile-menu .profile-logout-btn a.active {
  background-color: var(--primaryColor) !important;
  color: var(--whiteColor) !important;
  justify-content: center;
  margin: 5px;
}
.profile-menu .profile-logout-btn a.active:hover {
  background-color: var(--hover-primaryColor) !important;
}
.profile-logout-btn svg {
  color: var(--whiteColor);
  margin-right: 8px;
}
.profile-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-title-text {
  margin-top: -40px;
}
.profile-content .profile-upload {
  display: flex;
  align-items: center;
  gap: 12px;
}

.profile-upload .uploaded-text {
  width: 80px;
  height: 80px;
  background-color: var(--greyColor);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  color: var(--whiteColor);
  font-size: 32px;
  font-weight: 700;
}
.profile-content .edit-profile-btn {
  height: 36px !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.edit-profile-form .CTextField {
  margin-top: 20px;
}
.edit-profile-form {
  max-width: 500px;
}
.edit-profile-form .MuiOutlinedInput-root.Mui-disabled {
  opacity: 0.6 !important;
  -webkit-text-fill-color: var(--blackColor) !important;
}
.edit-profile-form .MuiFormLabel-root.Mui-disabled {
  color: var(--blackColor) !important;
  opacity: 0.7;
}
.edit-profile-form .MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: var(--blackColor) !important;
  opacity: 0.7;
}
.profile-toggle {
  position: absolute !important;
  top: 0;
  left: 0;
}
/*****404 css*****/
.page-not-found {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--thin-greyColor);
  overflow-y: hidden;
  text-align: center;
  padding: 24px;
}
.page-not-found .page-not-img {
  width: 100%;
  max-width: 450px;
  margin: 24px 0;
}
.page-not-found .logo-img {
  max-width: 172px;
  margin-bottom: 24px;
}
.page-not-found p {
  margin: 16px 0;
  max-width: 400px;
}
.page-not-found .btn-primary {
  min-width: 190px;
  margin-top: 16px;
}

.upload-input {
  border: 1px dashed var(--greyColor);
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--thin-greyColor);
  height: 125px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.upload-fields {
  width: calc(50% - 8px);
}

.upload-fields label {
  margin-bottom: 4px;
  display: block;
}
/****Contract pdf*****/


.contract-pdf-content li {
  list-style-type: none !important;
  position: relative;
}
.list-type-1,
.list-type-2 {
  padding: 12px 0 0 0;
}
.list-type-2 li,
.list-type-1 li {
  margin: 8px 0;
  font-size: 14px !important;
  line-height: 20px !important;
}
.list-type-1 li {
  padding-left: 30px;
}
.list-type-2 li {
  padding-left: 30px;
}
.contract-pdf-content li:before {
  content: attr(value) "";
  position: absolute;
  left: 0;
  padding-right: 5px;
}
.list-type-2 > li::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000000;
  top: 8px;
  padding: 0;
}
.list-type-3 > li::before {
  content: "";
  width: 6px;
  height: 1px;
  border-radius: 50%;
  background-color: #000000;
  top: 10px;
  padding: 0;
}
.contract-list-title {
  padding-left: 24px;
}
.contract-list-title:before {
  content: attr(value) "." !important;
}

.contract-list-title p,
.contract-list-title {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}

.list-type-3 li {
  padding-left: 30px;
}

.list-type-1 li {
  font-size: 16px !important;
  line-height: 22px !important ;
  font-weight: 600 !important;
}
.list-type-2 li {
  font-size: 15px !important;
  line-height: 21px !important;
  font-weight: normal !important;
}
.list-type-3 li {
  font-size: 15px !important;
  line-height: 21px !important;
  font-weight: normal !important;
}
.list-type-2 > .list-type_5 {
  font-weight: 600 !important;
}
.list-type-2 .contract-list-title-5 {
  font-weight: 500 !important;
}
.list-type-3 {
  padding: 8px 0 0 0;
}
.list-type-1 li.list-item-content,
.list-type-1 li .list-item-content {
  font-size: 15px !important;
  line-height: 21px !important;
  font-weight: normal !important;
}

.please-take-note-title:before {
  content: "" !important;
}
.contract-bottom-content {
  margin-top: 16px;
}
.contract-bottom-content p {
  font-size: 16px !important;
  line-height: 22px !important;
}
.contract-bottom-content p.contract-name {
  border-bottom: 1px solid #000000;
  padding-bottom: 4px;
}
.contract-bottom-content span {
  font-size: 15px !important;
  line-height: 21px !important;
  font-style: italic;
}
.contract-urs {
  padding: 16px 0;
}
.employee-sign {
  padding-top: 24px;
}
.contract-pdf-title {
  margin-bottom: 16px;
  font-size: 18px !important;
  line-height: 24px !important;
}
.contract-body:-webkit-scrollbar {
  width: 5px;
}
.contract-title-status {
  padding-left: 0 !important;
}
.contract-pdf-header-title {
  text-align: center;
  font-size: 18px !important;
  line-height: 24px !important;
  margin-bottom: 20px;
}

.contract-pdf-employer-header-title{
  text-align: center;
  font-size: 33px !important ;
  font-weight: 600;
  line-height: 24px ;
  margin-bottom: 20px;
}
.contract-pdf-table, .contract-pdf-table td {
  border: #000000 1px solid;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.wrap-text {
  white-space: normal; 
  padding-right: 5px;
  padding-left: 5px;
}



table.contract-pdf-table {
  margin-bottom: 30px;
}
.contract-pdf-header{
   margin-bottom: 20%;
}
.contract-pdf-table {
  width: 60%; /* Ensure the table takes full width */
}

.contract-pdf-table-row td:first-child {
  width: 35%; /* Adjust as needed */
}

.contract-pdf-table-row td:last-child {
  width: 65%; /* Adjust as needed */
}
.contract-pdf-table {
  border: #000000 2px solid; 
  margin: auto; 
}

.contract-pdf-table td {
  padding-left: 12px;
  padding-top: 20px; 
  text-align: left; 
  width: 70%;
}
.contract-pdf-right-title{
  padding-top: 20%;
  position: relative;
  text-align: right;
  padding-right: 18px;
}


input::placeholder,
input:focus::placeholder {
  opacity: 1 !important;
}


.scroll-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: var(--primaryColor);
  color: var(--whiteColor);
  border: 1px solid var(--primaryColor);
  border-radius: 50%;
  cursor: pointer;
  width: 50px !important;
  height: 50px !important;
  min-width: unset !important;
}
.scroll-top-btn svg {
  color: var(--whiteColor);
}
/***Img Uploader***/
.upload__image-wrapper .img-content {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-position: center;
  display: inline-block;
  overflow: hidden;
  border: 1px solid var(--greyColor2);
}
.upload__image-wrapper .img-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.upload__image-wrapper button.btn-text {
  padding: 0 !important;
  height: auto !important;
  color: var(--greyColor) !important;
  min-width: auto !important;
}
.upload__image-wrapper button.btn-text svg {
  font-size: 24px !important;
  margin: 4px;
}

.upload__image-wrapper .image-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.upload__image-wrapper .image-item__btn-wrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
}
.upload__image-wrapper .image-item__btn-wrapper span {
  color: var(--greyColor) !important;
}
/*****About Employer*****/
.about-container {
  padding: 0 !important;
}
.about-container .about-employer-content {
  padding: 24px;
  border: 1px solid var(--greyColor2);
  box-shadow: 0px 0px 8px 0px var(--shadow-blueColor);
  max-width: 850px;
  margin: 16px auto 0 auto;
  min-height: calc(100vh - 180px);
}
.about-container .about-employer-content .employer-logo {
  margin: 24px 0;
}
.about-container .about-employer-content .employer-logo img {
  width: 250px;
  height: auto;
  object-fit: contain;
  object-position: center;
}
.employer-details {
  padding: 24px 0;
}
.employer-details h3 {
  margin-bottom: 24px;
}
.employer-details .details-list {
  display: flex;
  align-items: start;
  margin: 8px 0;
}
.employer-details .details-list span {
  min-width: 170px;
  max-width: max-content;
  font-weight: bold;
}
.employer-details .details-list p {
  color: var(--greyColor);
}
/****Wallet Css***/
.wallet-content p {
  color: var(--greyColor);
}
.wallet-content span {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  margin-top: 4px;
  display: block;
}
.employer-img-list span {
  display: block;
  margin-bottom: 8px;
  width: 100%;
}
.employer-details .employer-img-list {
  display: block;
}
.employer-details .employer-img-list img {
  height: auto;
  max-width: 650px;
  object-fit: contain;
  width: 100%;
  margin: auto !important;
  display: block;
}
.card.disabled {
  background-color: #f5f5f5;
  pointer-events: none;
  opacity: 0.6;
}
